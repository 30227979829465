<template>
    <div class="product-homepage-mobile">
        <div :key="ix" :style="{background:getBackground(floor)}" class="floor" v-for="(floor,ix) in floors">
            <div class="head">
                <h3>{{floor.homepage.label}}</h3>
                <router-link :to="getViewItemsLink(floor)" class="md-primary md-raised" tag="md-button">View Items</router-link>
            </div>
            <div class="content">
                <div class="products">
                    <product-card :key="ix" :product="product" v-for="(product,ix) in floor.products"></product-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductCard from "@/components/Product/ProductCard";
import { mapGetters, mapState, mapActions } from "vuex";

import qs from "qs";
export default {
    props: {
        vendor: String
    },
    components: {
        ProductCard
    },
    data() {
        return {
            floors: []
        };
    },
    computed: {
        ...mapGetters({
            vendorMode: "vendorMode",
            affiliateId: "affiliateId",
            location: "location/location",
            defaultLocation: "location/defaultLocation",
            categories: "category/categories"
        }),
        getViewItemsLink() {
            return floor => {
                let homepage = floor.homepage;
                let qsobj = {
                    hotOnly: homepage.hotOnly || undefined,
                    newOnly: homepage.newOnly || undefined,
                    saleOnly: homepage.saleOnly || undefined
                };
                let category = this.getCategory(floor);
                if (category && floor.homepage.category_id) {
                    qsobj.level = category && category.level;
                    return `/category/${category.slug}?${qs.stringify(qsobj)}`;
                } else {
                    return `/shop?${qs.stringify(qsobj)}`;
                }
            };
        },
        getBackground() {
            let googleimage = this.$options.filters.googleimage;
            return floor => {
                let homepage = floor.homepage;
                return homepage.backgroundImage ? `url(${googleimage(homepage.backgroundImage)}) no-repeat center center / cover` : homepage.backgroundColor;
            };
        },
        getCategory() {
            return floor => {
                let category = this.categories.find(c => c._id == floor.homepage.category_id);
                return category;
            };
        }
    },
    watch: {
        location(n, o) {
            if (o) {
                console.info("reload products");
                this.loadProducts();
            }
        }
    },
    methods: {
        ...mapActions({
            loadCategories: "category/loadCategories",
            loadLocations: "location/loadLocations"
        }),
        async loadProducts() {
            let location = this.location || this.defaultLocation;
            let result = await this.$store.dispatch("crud/get", {
                api: "homepages/products",
                params: {
                    mobile: 1,
                    location: location && location._id,
                    vendor: this.vendor || (this.vendorMode && this.vendorMode._id),
                    affiliateId: this.affiliateId
                }
            });
            this.floors = result;
        }
    },
    async created() {
        await this.loadLocations();
        await this.loadCategories();
        this.loadProducts();
    }
};
</script>
<style lang="less">
.product-homepage-mobile {
    width: 100%;
    .floor {
        // margin-bottom: 10px;
    }
    .head {
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }
    .content {
        .products {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
}
</style>
