<template>
  <div class="swiper-item" :class="{'is-active': active}" :style="{width: `${width}px`}">
    <div v-if="ready" class="swiper-item__container">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="less">
.swiper-item {
    position: relative;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
}
</style>
<script>
export default {
    name: "swiper-item",
    props: {
        name: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            width: 0,
            ready: false,
            active: false
        };
    },
    methods: {
        changeActiveIndex(index, activeIndex) {
            if (index === activeIndex) {
                this.ready = true;
                this.active = true;
            } else {
                this.active = false;
            }
        }
    },
    created() {
        this.$parent && this.$parent.$parent && this.$parent.$parent.updateItems();
    },
    destroyed() {
        this.$parent && this.$parent.$parent && this.$parent.$parent.updateItems();
    }
};
</script>
