<template>
    <div class="home">
        <swiper :autoplay="true" :height="clientWidth * 0.195" :initialIndex="0" :interval="4000" :loop="true" :maxDistance="100" :width="clientWidth" v-if="banners.length && !category && !vendorMode">
            <swiper-item :key="idx" v-for="(banner, idx) in banners">
                <a :href="banner.url || 'javascript:void 0'" aria-label="banner">
                    <img :src="banner.image | googleimage" alt class="banner" />
                </a>
            </swiper-item>
        </swiper>
        <img :src="categoryBanner | googleimage" alt class="banner" v-if="category && categoryBanner && !vendorMode" />
        <breadcrumb :text="breadcrumbText" v-if="category && !categoryBanner && !vendorMode"></breadcrumb>
        <div class="main-product-container">
            <side-menu :category="category" v-if="category"></side-menu>
            <product-view :category="category" :hotOnly="hotOnly" :newOnly="newOnly" :q="q" :saleOnly="saleOnly" v-if="vendorMode || q || category || hotOnly || newOnly || saleOnly"></product-view>
            <product-homepage v-else-if="!isMobile"></product-homepage>
            <product-homepage-mobile v-else></product-homepage-mobile>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import { currency } from "@/config";
import ProductView from "@/components/Product/ProductView";
import Swiper from "@/components/Swiper";
import SwiperItem from "@/components/SwiperItem";
import Breadcrumb from "@/components/Breadcrumb";
import SideMenu from "@/components/Menu/SideMenu";
import ProductHomepage from "@/components/Product/ProductHomepage";
import ProductHomepageMobile from "@/components/Product/ProductHomepageMobile";

export default {
    props: {
        category: String,
        q: String,
        hotOnly: String,
        newOnly: String,
        saleOnly: String
    },
    components: {
        Swiper,
        SwiperItem,
        ProductView,
        Breadcrumb,
        SideMenu,
        ProductHomepage,
        ProductHomepageMobile
    },
    data() {
        return {
            banners: [],
            categoryBanner: "",
            breadcrumbText: []
        };
    },
    watch: {
        category() {
            this.loadCategoryBanner();
        }
    },
    computed: {
        ...mapState({
            clientWidth: state => state.clientWidth || 0
        }),
        ...mapGetters({
            getCategoryBySlug: "category/getCategoryBySlug",
            getParentsById: "category/getParentsById",
            vendorMode: "vendorMode"
        }),
        isMobile() {
            return window.screen.width <= 600;
        }
    },

    methods: {
        async loadCategoryBanner() {
            let category = this.getCategoryBySlug(this.category);
            if (category && category.showBanner && category.banner) {
                this.categoryBanner = category.banner;
            } else {
                this.categoryBanner = "";
                this.breadcrumbText = [];
                if (category) {
                    let parents = this.getParentsById(category._id);

                    this.breadcrumbText = parents.map(p => ({ url: `/category/${p.slug}?level=${p.level}`, name: p.name }));
                    this.breadcrumbText.push({ url: `/category/${category.slug}?level=${category.level}`, name: category.name });
                }
            }
        },
        async loadBannerSetting() {
            const data = await this.$store.dispatch("crud/get", {
                api: "settings/banner"
            });
            if (data && data.value && data.value.length) {
                this.banners = data.value.sort((a, b) => (a.rank || 0) - (b.rank || 0)) || [];
            } else {
                this.banners.push({
                    url: "javascript:void 0",
                    image: "/static/WebBanner.jpg"
                });
            }
        }
    },
    async created() {
        await this.$store.dispatch("category/loadCategories");

        this.loadCategoryBanner();

        this.loadBannerSetting();
    }
};
</script>
<style lang="less">
.home {
    padding-top: 0;
    .banner {
        width: 100%;
        margin-bottom: 10px;
    }
    .main-product-container {
        display: flex;
        .product-view {
            flex: 1;
        }
    }
}
@media (max-width: 600px) {
    .home {
        .main-product-container {
            .side-menu {
                display: none;
            }
        }
    }
}
</style>