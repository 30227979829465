<template>
  <div ref="_wrap" @touchstart.stop="handleTouchStart" @touchmove.stop="handleTouchMove" @touchend.stop="handleTouchEnd" class="common-touch">
    <slot></slot>
  </div>
</template>

<script>
export default {
    data() {
        return {
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
            distanceX: 0,
            distanceY: 0
        };
    },
    methods: {
        handleTouchStart(e) {
            const { changedTouches } = e;
            this.startX = changedTouches[0].pageX;
            this.startY = changedTouches[0].pageY;
            this.$emit("touchstart", this._getTouchInfo(e));
        },
        handleTouchMove(e) {
            const { changedTouches } = e;
            this.endX = changedTouches[0].pageX;
            this.endY = changedTouches[0].pageY;
            this._setDirection();
            this.$emit("touchmove", this._getTouchInfo(e));
        },
        handleTouchEnd(e) {
            this.$emit("touchend", this._getTouchInfo(e));
        },
        _getTouchInfo(e) {
            const { startX, startY, endX, endY, distanceX, distanceY } = this;
            return {
                event: e,
                startX,
                startY,
                endX,
                endY,
                distanceX,
                distanceY
            };
        },
        _setDirection() {
            const { startX, startY, endX, endY } = this;
            this.distanceX = endX - startX;
            this.distanceY = endY - startY;
        }
    }
};
</script>
