<template>
    <div class="product-homepage">
        <div :key="ix" class="floor" :style="{ background: getBackground(floor) }" v-for="(floor, ix) in floors">
            <div class="left">
                <h3>{{ floor.homepage.label }}</h3>
                <router-link :to="getViewItemsLink(floor)" class="md-primary md-raised" tag="md-button">View Items</router-link>
            </div>
            <div class="right">
                <div @click="moveRight(ix)" class="arrow arrow-left">
                    <md-icon>chevron_left</md-icon>
                </div>
                <div class="products-container">
                    <div :style="{ left: getFloorIndex(ix) * -220 + 'px' }" class="products">
                        <product-card :key="ix" :product="product" v-for="(product, ix) in floor.products"></product-card>
                    </div>
                </div>
                <div @click="moveLeft(ix)" class="arrow arrow-right">
                    <md-icon>chevron_right</md-icon>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductCard from "@/components/Product/ProductCard";
import { mapGetters, mapState, mapActions } from "vuex";
import qs from "qs";
export default {
    props: {
        vendor: String,
    },
    components: {
        ProductCard,
    },
    data() {
        return {
            floors: [],
            floorsIndexs: [],
        };
    },
    computed: {
        ...mapGetters({
            vendorMode: "vendorMode",
            affiliateId: "affiliateId",
            location: "location/location",
            defaultLocation: "location/defaultLocation",
            categories: "category/categories",
        }),
        getViewItemsLink() {
            return (floor) => {
                let homepage = floor.homepage;
                let qsobj = {
                    hotOnly: homepage.hotOnly || undefined,
                    newOnly: homepage.newOnly || undefined,
                    saleOnly: homepage.saleOnly || undefined,
                };
                let category = this.getCategory(floor);
                if (category && floor.homepage.category_id) {
                    qsobj.level = category && category.level;
                    return `/category/${category.slug}?${qs.stringify(qsobj)}`;
                } else {
                    return `/shop?${qs.stringify(qsobj)}`;
                }
            };
        },
        getCategory() {
            return (floor) => {
                let category = this.categories.find((c) => c._id == floor.homepage.category_id);
                return category;
            };
        },
        getFloorIndex() {
            console.info("triggered");
            return (ix) => {
                return (this.floorsIndexs[ix] && this.floorsIndexs[ix].index) || 0;
            };
        },
        getBackground() {
            let googleimage = this.$options.filters.googleimage;
            return (floor) => {
                let homepage = floor.homepage;
                if (!homepage.mobileViewOnly) {
                    return homepage.backgroundImage ? `url(${googleimage(homepage.backgroundImage)}) no-repeat center center / cover` : homepage.backgroundColor;
                } else {
                    return "";
                }
            };
        },
    },
    watch: {
        location(n, o) {
            console.info(n, o);
            if (o) {
                console.info("reload products");

                this.loadProducts();
            }
        },
    },
    methods: {
        ...mapActions({
            loadCategories: "category/loadCategories",
            loadLocations: "location/loadLocations",
        }),
        async loadProducts() {
            let location = this.location || this.defaultLocation;
            let result = await this.$store.dispatch("crud/get", {
                api: "homepages/products",
                params: {
                    location: location && location._id,
                    vendor: this.vendor || (this.vendorMode && this.vendorMode._id),
                    affiliateId: this.affiliateId,
                },
            });
            this.floors = result;
        },
        moveLeft(ix) {
            this.floorsIndexs[ix] = this.floorsIndexs[ix] || {};
            this.floorsIndexs[ix].index = this.floorsIndexs[ix].index || 0;
            if (this.floorsIndexs[ix].index >= this.floors[ix].products.length - 1) {
                return;
            }

            this.floorsIndexs[ix].index++;
            this.$set(this.floorsIndexs, ix, this.floorsIndexs[ix]);
            this.$set(this.floorsIndexs[ix], "index", this.floorsIndexs[ix].index);
        },
        moveRight(ix) {
            this.floorsIndexs[ix] = this.floorsIndexs[ix] || {};
            this.floorsIndexs[ix].index = this.floorsIndexs[ix].index || 0;
            if (this.floorsIndexs[ix].index <= 0) {
                return;
            }

            this.floorsIndexs[ix].index--;
            this.$set(this.floorsIndexs, ix, this.floorsIndexs[ix]);
            this.$set(this.floorsIndexs[ix], "index", this.floorsIndexs[ix].index);
        },
    },
    async created() {
        await this.loadLocations();
        await this.loadCategories();
        this.loadProducts();
    },
};
</script>

<style lang="less">
.product-homepage {
    width: 100%;
    .floor {
        display: flex;
        width: 100%;
        // border: 1px solid #eeeeee;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
        margin-bottom: 20px;
    }
    .left {
        width: 140px;
        margin-right: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .right {
        flex: 1;
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .arrow-left {
        width: 30px;
        height: 50px;
        background: rgba(0, 0, 0, 0.05);
        line-height: 50px;
        text-align: center;
        cursor: pointer;
        transition: all linear 0.2s;
        user-select: none;
        &:active {
            transform: scale(0.9);
        }
    }

    .arrow-right {
        width: 30px;
        height: 50px;
        background: rgba(0, 0, 0, 0.05);
        line-height: 50px;
        text-align: center;
        margin-left: 10px;
        cursor: pointer;
        transition: all linear 0.2s;
        user-select: none;
        &:active {
            transform: scale(0.9);
        }
    }
    .products-container {
        flex: 1;
        overflow: hidden;
        margin-top: 35px;
        margin-bottom: 35px;
        position: relative;
        height: 290px;
    }
    .products {
        // display: flex;
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;
        transition: all linear 0.2s;

        .product-card {
            width: 200px;
            display: inline-block;
            .md-card-header {
                padding: 5px;
            }
            .price {
                margin-top: 10px;
            }
            .vendor__name {
                margin-top: 10px;
            }
        }
    }
}
</style>
