<template>
    <div :style="{width: `${width}px`,height: `${height}px`,overflow:'hidden'}">
        <touch @touchend="handleTouchEnd" @touchmove="handleTouchMove" @touchstart="handleTouchStart" class="swiper">
            <div @click="prev" class="swiper-arrow swiper-arrow__prev" v-if="showArrow && items.length > 1">
                <md-icon>chevron_left</md-icon>
            </div>
            <div :style="containerStyle" class="swiper-container">
                <slot></slot>
            </div>
            <div @click="next" class="swiper-arrow swiper-arrow__next" v-if="showArrow && items.length > 1">
                <md-icon>chevron_right</md-icon>
            </div>
        </touch>
    </div>
</template>

<script>
import Touch from "@/components/Touch";

export default {
    components: {
        Touch
    },
    props: {
        showArrow: {
            type: Boolean,
            default: true
        },
        interval: {
            type: Number,
            default: 2000
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        loop: {
            type: Boolean,
            default: false
        },
        animateTime: {
            type: Number,
            default: 500
        },
        initialIndex: {
            type: Number,
            default: 0
        },
        maxDistance: {
            type: Number,
            default: 100
        },
        width: {
            type: Number
        },
        height: {
            type: Number
        },
        tabMode: {
            type: Boolean,
            default: false
        }
        // '@change'
    },
    data() {
        return {
            items: [],
            isTouch: false,
            activeIndex: -1,
            distanceX: 0,
            distanceY: 0,
            animation: false,
            timer: null
        };
    },
    computed: {
        containerStyle() {
            const style = {};
            const { width, height, items, isTouch, animateTime, activeIndex, distanceX, tabMode } = this;
            const itemLen = items.length;
            const x = tabMode ? 0 : distanceX;
            const distance = -activeIndex * width + x;
            style.width = `${width * itemLen}px`;
            style.height = `${height}px`;
            style.transition = isTouch && !tabMode ? "none" : `transform ${animateTime}ms ease`;
            style.transform = `translate3d(${distance}px, 0, 0)`;
            return style;
        }
    },
    methods: {
        updateItems() {
            this.items = this.$children[0].$children.filter((child, index) => {
                if (child.$options.name === "swiper-item") {
                    child.changeActiveIndex(index, this.activeIndex);
                    child.width = this.width;
                    return true;
                }
            });
        },
        changeActiveIndex() {
            this.items.forEach((item, index) => {
                item.changeActiveIndex(index, this.activeIndex);
            });
        },
        handleTouchStart(touchInfo) {
            this.isTouch = true;
            this.pauseTimer();
        },
        handleTouchMove(touchInfo) {
            this.isTouch = true;
            const { distanceX = 0, distanceY = 0, event } = touchInfo;
            if (Math.abs(distanceX) >= 40) {
                event.preventDefault();
            }
            this.distanceX = distanceX;
            this.distanceY = distanceY;
        },
        handleTouchEnd() {
            this.isTouch = false;
            const { distanceX = 0, maxDistance } = this;
            if (Math.abs(distanceX) >= maxDistance) {
                if (distanceX > 0) {
                    this.prev();
                } else {
                    this.next();
                }
            } else {
                this.distanceX = 0;
            }
            this.startTimer();
        },
        prev() {
            this.setActiveItem(this.activeIndex - 1);
        },
        next() {
            this.setActiveItem(this.activeIndex + 1);
        },
        setActiveItem(index) {
            const { loop, items, animateTime } = this;
            if (typeof index === "string") {
                index = items.findIndex(item => item.name === index);
            }
            index = parseInt(index, 10);
            if (isNaN(index) || index !== Math.floor(index)) return;
            const itemLen = items.length;
            let activeIndex = index;
            if (index < 0) {
                activeIndex = loop ? itemLen - 1 : 0;
            } else if (index >= itemLen) {
                activeIndex = loop ? 0 : itemLen - 1;
            }
            this.activeIndex = activeIndex;
            this.distanceX = 0;
            this.animation = true;
            setTimeout(() => {
                this.animation = false;
            }, animateTime);
        },
        autoPlay() {
            this.distanceX = 0;
            this.next();
        },
        startTimer() {
            if (this.interval <= 0 || !this.autoplay) return;
            this.pauseTimer();
            // this.autoPlay();
            this.timer = setInterval(this.autoPlay, this.interval);
        },
        pauseTimer() {
            clearInterval(this.timer);
        }
    },
    watch: {
        activeIndex(activeIndex, oldIndex) {
            this.changeActiveIndex();
            this.$emit("change", {
                oldIndex,
                activeIndex,
                activeName: this.items[activeIndex] && this.items[activeIndex].name
            });
        },
        width() {
            this.updateItems();
        }
    },
    mounted() {
        setTimeout(() => {
            this.updateItems();
            this.$nextTick(() => {
                this.activeIndex = this.initialIndex;
                this.startTimer();
            });
        }, 300);
    },
    beforeDestroy() {
        this.pauseTimer();
    }
};
</script>
<style lang="less">
.swiper {
    position: relative;

    &.common-touch {
        width: 100%;
        height: 100%;
    }

    .swiper-container {
        display: flex;
        flex-wrap: nowrap;
        position: absolute;
    }

    .swiper-arrow {
        z-index: 2;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        width: 45px;
        height: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        background: #fff;
        color: #212121;
        border-radius: 5px 0 0 5px;
        cursor: pointer;

        &.swiper-arrow__prev {
            left: 0;
        }

        &.swiper-arrow__next {
            right: 0;
        }

        &:hover {
            opacity: 0.9;
        }
    }

    @media (max-width: 600px) {
        .swiper-arrow {
            overflow: hidden;
            width: 0;
        }
    }
}
</style>