<template>
    <div class=" breadcrumb-bar">
        <ul class="breadcrumb left">
            <li>
                <router-link to="/">Home</router-link>
            </li>
            <li v-for="(i,ix) in text" :key="ix">
                &nbsp;>
                <router-link :to="i.url" v-if="i.url">{{i.name}}</router-link>
                <span v-else>{{i.name}}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ["text"],

    methods: {}
};
</script>
<style lang="less">
.breadcrumb-bar {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.08), 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 5px 0 rgba(0, 0, 0, 0.05);

    flex: 1;
    .breadcrumb {
        padding: 15px;
        display: flex;
        margin: 0;
    }

    .breadcrumb li {
        margin-top: 0;
        list-style: none;
    }

    .breadcrumb li a {
        font-size: 1rem;
        font-weight: 400;
    }
}
</style>